import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit,
    ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { Event, NavigationEnd, Router } from "@angular/router";
import { interval } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Category } from "../app.models";
import { AppSettings, Settings } from "../app.settings";
import { CatalogoLink } from "../interfaces/catalogo-link.model";
import { BuscaItem, BuscaService } from "../services/busca/busca.service";
import {
    CarrinhoRetorno,
    CarrinhoRetornoItens,
    CartService,
    ResumoCarrinho,
} from "../services/cart/cart.service";
import { SessionService, SignIn } from "../services/session/session.service";
import {
    ObterIdentificador,
    ObterIdentificadorService,
} from "../services/sign-in/obter-identificador.service";
import { UtilService } from "../services/util/util.service";
import { ModalContactComponent } from "../shared/modal-contact/modal-contact.component";
import { SidenavMenuService } from "../theme/components/sidenav-menu/sidenav-menu.service";
import { log } from "console";
import { HttpErrorResponse } from "@angular/common/http";
import { ModalCartComponent } from "../shared/modal-cart/modal-cart.component";

@Component({
    selector: "app-pages",
    templateUrl: "./pages.component.html",
    styleUrls: ["./pages.component.scss"],
    providers: [SidenavMenuService],
})
export class PagesComponent implements OnInit, AfterViewInit {
    public showBackToTop = false;

    public log;

    public categories: Category[];

    public category: Category;

    public sidenavMenuItems: Array<any>;

    public isLogged = false as boolean;

    @ViewChild("sidenav", { static: true }) sidenav: any;

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    @ViewChild("languageMenuTrigger") languageMenuTrigger: MatMenuTrigger;

    lista: Array<BuscaItem> = [];

    pesquisaForm: FormGroup;

    public settings: Settings;

    resumoCarrinho: ResumoCarrinho;

    codCliente: string;

    carrinho: CarrinhoRetorno;

    dadosEmpresa: ObterIdentificador;

    qtdeTotalItens: number;

    public codTabelaPreco = "";

    private itensOriginal: Array<CarrinhoRetornoItens> = [];

    public quantidadeLotes: number;

    public linkWhatsapp: string;

    public loading: boolean;

    public whatsapp: string;

    public catalogosBase: Array<CatalogoLink> = [
        {
            href: "https://catalog.minoricasa.com/",
            name: "Catalog",
            icon: "library_books",
        },
    ];
    public menu: Array<CatalogoLink>;

    public linkb2b: string;

    public cartSelecionado: string =
        "https://minori-casa.s3.amazonaws.com/site-images/icons/cart.png";

    public logoSelecionado: string =
        "https://www.minoricasa.com/assets/images/logo/logo-bege-escuro.png";
    public logoWhatsAppSelecionado: string =
        "../../assets/images/icons/icon-whatsapp-black.ico";
    public logoLupaSelecionado: string =
        "../../assets/icons/lupa-icon-black.png";
    public logoCarrinhoSelecionado: string =
        "../../assets/icons/carrinho-icon-black.png";

    public links = {
        wharehouse3D:
            "https://3dwarehouse.sketchup.com/by/minoricasa?tab=collections",
    };

    public isHome = false;
    public linksLogo = {
        positivo: "../../assets/images/logo/logo-branco.svg",
        negativo:
            "https://www.minoricasa.com/assets/images/logo/logo-bege-escuro.png",
        preto: "../../assets/images/logo/logo-preto.svg",
        mobile: "https://maioricasa.s3.amazonaws.com/logos/m-maioricasa-marrom.svg",
    };

    public userIcon = {
        logged: "https://minori-casa.s3.amazonaws.com/site-images/icons/account-user-logged.png",
        user: "https://minori-casa.s3.amazonaws.com/site-images/icons/account-user.png",
    };

    public cartIcon = {
        withItens:
            "https://minori-casa.s3.amazonaws.com/site-images/icons/cart-with-itens.png",
        noItens:
            "https://minori-casa.s3.amazonaws.com/site-images/icons/cart.png",
    };

    private linksWhats = {
        positivo: "../../assets/images/icons/icon-whatsapp.png",
        negativo: "../../assets/images/icons/icon-whatsapp-black.ico",
    };

    public cartLogo = {
        positivo: "../../assets/icons/carrinho-icon.png",
        negativo: "../../assets/icons/carrinho-icon-black.png",
    };

    public whatsappLogo = {
        positivo: "../../assets/icons/whatsapp-icon-white.png",
        negativo: "../../assets/icons/whatsapp-icon-black.png",
    };

    public lupaLogo = {
        positivo: "../../assets/icons/lupa-icon.png",
        negativo: "../../assets/icons/lupa-icon-black.png",
    };

    public menus: Array<any> = [
        {
            rota: "/about",
            rotulo: "About Us",
            possuiSubMenu: false,
            possuiAcao: false,
            menuSelecionado: false,
            link: "",
            id: 1,
        },
        {
            rota: "/products/Rugs/000151/2",
            rotulo: "Rugs",
            possuiSubMenu: false,
            possuiAcao: false,
            menuSelecionado: false,
            link: "",
        },
        // {
        //   rota: '/rug-care',
        //   rotulo: 'Rug Care',
        //   possuiSubMenu: false,
        //   possuiAcao: false,
        //   menuSelecionado: false,
        //   link: '',
        //   id: 3
        // },
        {
            rota: "/catalog",
            rotulo: "Catalog",
            possuiSubMenu: false,
            possuiAcao: true,
            menuSelecionado: false,
            link: "",
            id: 3,
        },
        // {
        //   rota: '/events',
        //   rotulo: 'Events',
        //   possuiSubMenu: true,
        //   possuiAcao: false,
        //   menuSelecionado: false,
        //   link: '',
        //   id: 4
        // },
        {
            rota: "/media",
            rotulo: "Media",
            possuiSubMenu: false,
            possuiAcao: false,
            menuSelecionado: false,
            link: "",
        },
        {
            rota: "/contato",
            rotulo: "Contact",
            possuiSubMenu: false,
            possuiAcao: true,
            menuSelecionado: false,
            link: "",
            id: 5,
        },
        {
            rota: "/samples-program",
            rotulo: "Samples Program",
            possuiSubMenu: false,
            possuiAcao: false,
            menuSelecionado: false,
            link: "",
            id: 6,
        },
        // {
        //   rota: '/open-trade-account',
        //   rotulo: 'Open Trade Account',
        //   possuiSubMenu: false,
        //   possuiAcao: false,
        //   menuSelecionado: false,
        //   link: '',
        //   id: 7
        // },
    ];

    public subItems: Array<any> = [
        {
            id: 2,
            itens: [
                {
                    link: "/products/Rugs/000151/2",
                    name: "Indoor",
                    icon: "library_books",
                    possuiAcao: true,
                    linkInterno: true,
                },
                {
                    link: "/products/Rugs/000164/2",
                    name: "Outdoor",
                    icon: "library_books",
                    possuiAcao: true,
                    linkInterno: true,
                },
                {
                    link: "/rug-care",
                    name: "Rug Care",
                    icon: "library_books",
                    possuiAcao: true,
                    linkInterno: true,
                },
            ],
        },
        {
            id: 3,
            itens: [
                {
                    link: "https://catalog.minoricasa.com/",
                    name: "Tecidos Outdoor",
                    icon: "library_books",
                    possuiAcao: true,
                    linkInterno: false,
                },
            ],
        },
        {
            id: 4,
            itens: [
                {
                    link: "/events",
                    name: "Events",
                    icon: "library_books",
                    possuiAcao: true,
                    linkInterno: true,
                },
                {
                    link: "/media",
                    name: "Media",
                    icon: "library_books",
                    possuiAcao: true,
                    linkInterno: true,
                },
            ],
        },
    ];

    public rotaProdutos = false;
    public isMobile = false;
    public isSamples = false;

    public showSearch: boolean = false;
    constructor(
        public appSettings: AppSettings,
        public sidenavMenuService: SidenavMenuService,
        public router: Router,
        private sessionService: SessionService,
        private buscaService: BuscaService,
        private cartService: CartService,
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private obterIdentificadorService: ObterIdentificadorService,
        private utilService: UtilService,
        public dialog: MatDialog,
        public title: Title,
        private cdr: ChangeDetectorRef
    ) {
        // this.isMobile = this.verificaMobile();
        this.isMobile = screen.width > 768 ? false : true;
        console.log(this.isMobile);

        this.title.setTitle("Home - Minori Casa");

        this.settings = this.appSettings.settings;
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.verificaMenu();
                if (event.url === "/") {
                    this.isHome = true;
                    this.isSamples = false;
                    this.logoWhatsAppSelecionado = this.linksWhats.positivo;
                    this.logoLupaSelecionado = this.lupaLogo.positivo;
                    this.logoCarrinhoSelecionado = this.cartLogo.positivo;
                    if (this.isHome && !this.isMobile) {
                        this.logoSelecionado = this.linksLogo.positivo;
                    } else {
                        this.logoSelecionado = this.linksLogo.negativo;
                    }
                    this.rotaProdutos = false;
                } else if (event.url.indexOf("pesquisa") !== -1) {
                    // this.closeMenu();
                    this.zerarLista();
                    this.isHome = false;
                    this.logoSelecionado = this.linksLogo.negativo;
                    this.logoWhatsAppSelecionado = this.linksWhats.negativo;
                    this.logoLupaSelecionado = this.lupaLogo.negativo;
                    this.logoCarrinhoSelecionado = this.cartLogo.negativo;
                    this.rotaProdutos = false;
                }
                //    else if (event.url === ('/gift-samples') || ('gift-samples/samples-success')) {
                //     this.isSamples = true
                //   }
                else {
                    this.isHome = false;
                    this.isSamples = false;
                    this.logoWhatsAppSelecionado = this.linksWhats.negativo;
                    this.logoLupaSelecionado = this.lupaLogo.negativo;
                    this.logoCarrinhoSelecionado = this.cartLogo.negativo;
                    this.logoSelecionado = this.linksLogo.negativo;
                    this.rotaProdutos = true;
                }
            }
        });

        this.menus.forEach((item) => {
            if (this.router.url.indexOf(`${item.rota}`) != -1) {
                item.menuSelecionado = true;
                this.isSamples = false;
            } else {
                item.menuSelecionado = false;
                this.isSamples = false;
            }
        });
    }

    ngOnInit() {
        this.sessionService.isLogged$.subscribe((data) => {
            this.log = data;
            if (this.log === true) {
                this.isLogged = true;
            } else {
                this.isLogged = false;
            }
            this.menus.forEach((item) => {
                if (item.id === 3) {
                    if (this.isLogged) {
                        item.link = "https://catalog.minoricasa.com/";
                    } else {
                        item.link = "https://campaign.minoricasa.com/catalog";
                    }
                }
            });
        });

        this.cartService.emitCart.pipe(debounceTime(1000)).subscribe((data) => {
            this.getResumoCarrinho();
        });

        this.cartService.emitPedido.subscribe((data) => {
            this.resumoCarrinho.total = 0;
            this.resumoCarrinho.quantidade = 0;
            this.resumoCarrinho.totalSemIpi = 0;
            this.carrinho.itens = [];
            this.qtdeTotalItens = 0;
        });

        this.pesquisaForm = this.fb.group({
            valorPesquisa: ["", []],
        });

        this.sessionService.identificadorUrl();

        this.sessionService.information$.subscribe((data: SignIn) => {
            if (data) {
                if (
                    data.codCliente == null ||
                    data.codCliente === "" ||
                    data.codTabelaPreco == null ||
                    data.codTabelaPreco === ""
                ) {
                    this.codCliente = "";
                    this.codTabelaPreco = "";
                } else {
                    this.codCliente = data?.codCliente;
                    this.codTabelaPreco = data?.codTabelaPreco;
                }
            } else {
                this.codCliente = "";
                this.codTabelaPreco = "";
            }
        });

        this.getResumoCarrinho();

        this.sessionService.codTabelaPreco$.subscribe((data) => {
            this.codTabelaPreco = data;
        });

        this.obterIdentificadorService
            .obterIdentificador()
            .subscribe((data: ObterIdentificador) => {
                this.dadosEmpresa = data;
                this.linkb2b = `https://b2b.${
                    this.dadosEmpresa.site.split("www.")[1]
                }`;

                const valorAtual = data.telefone;
                const isCelular = valorAtual.length === 9;
                let telMask;
                if (isCelular) {
                    const x = valorAtual.slice(0, 5);
                    const y = valorAtual.slice(5, 9);
                    telMask = `${x}-${y}`;
                } else {
                    const x = valorAtual.slice(0, 4);
                    const y = valorAtual.slice(4, 8);
                    telMask = `${x}-${y}`;
                }

                data.telefone = telMask;
                this.linkWhatsapp = this.getLinkWhats();
            });

        interval(1000).subscribe((_) => {
            this.sessionService.isActive();
            this.verificaMenu();
            if (this.router.url === "/") {
                this.title.setTitle("Home - Minori Casa");
            }
        });
        this.pesquisaForm.valueChanges
            .pipe(debounceTime(500))
            .subscribe((item) => {
                if (item.valorPesquisa.length > 2) {
                    this.searchList(item.valorPesquisa, false);
                } else if (
                    item.valorPesquisa.length > 1 &&
                    item.valorPesquisa.length <= 2
                ) {
                    this.snackBar.open(
                        "Please enter at least 3 characters.",
                        "",
                        {
                            panelClass: "warn",
                            verticalPosition: "bottom",
                            duration: 2000,
                        }
                    );
                    this.lista = [];
                } else {
                    this.lista = [];
                }
            });

        this.menus.forEach((item) => {
            if (this.router.url.indexOf(`${item.rota}`) != -1) {
                item.menuSelecionado = true;
            } else {
                item.menuSelecionado = false;
            }
        });
    }

    ngAfterViewChecked() {
        this.menus.forEach((item) => {
            if (this.router.url.indexOf(`${item.rota}`) != -1) {
                item.menuSelecionado = true;
            } else {
                item.menuSelecionado = false;
            }
        });

        // this.isMobile = this.verificaMobile();
        this.isMobile = screen.width > 768 ? false : true;
        this.cdr.detectChanges();
    }

    stopPropagation(event): void {
        event.stopPropagation();
    }

    public changeCategory(event) {
        if (event.target) {
            this.category = this.categories.filter(
                (category) => category.name === event.target.innerText
            )[0];
        }
        if (window.innerWidth < 960) {
            this.stopClickPropagate(event);
        }
    }

    public changeTheme(theme) {
        this.settings.theme = theme;
    }

    public getLinkWhats() {
        const retorno = `https://api.whatsapp.com/send?phone=13059273050`;
        this.whatsapp = this.utilService.mascaraDeTelefoneDDD(
            this.dadosEmpresa.whatsapp
        );
        return retorno;
    }

    public stopClickPropagate(event: any) {
        event.stopPropagation();
        event.preventDefault();
    }

    openMenu() {
        this.languageMenuTrigger.openMenu();
    }

    closeMenu() {
        this.languageMenuTrigger.closeMenu();
    }

    public searchList(value, origemHome: boolean) {
        this.searchListPromise(value, origemHome, (status: any, error: any) => {
            if (error === true) {
            } else if (status === false) {
            }
        });
    }

    public searchListPromise(value, origemHome: boolean, callback?: any) {
        if (value.length > 2) {
            this.buscaService.buscaPorProdutos(value).subscribe({
                next: (data: any) => {
                    if (data.status === 0) {
                        let products = data.dados;
                        products.map((item) => {
                            if (item.imagens.length > 0) {
                                item.achouImg = true;
                            } else {
                                item.achouImg = false;
                            }
                        });

                        products = products.filter(
                            (prod) => prod.achouImg === true
                        );
                        this.lista = products;

                        if (this.lista.length <= 0) {
                            this.lista.push({
                                codigo: "",
                                descricao: "No products found for this search",
                                urlGrade: "assets/images/others/error-24px.svg",
                                imagens: [
                                    {
                                        tipoImagem: 1,
                                        url: "assets/images/others/search.svg",
                                    },
                                ],
                                codigoGrade: "resultadoPesquisa",
                                ordem: 0,
                                codigoCor: "",
                                descricaoCor: "",
                                descricaoGrade:
                                    "No products found for this search",
                            });
                        } else {
                            this.lista.push({
                                codigo: "resultadoPesquisa",
                                descricao: "All Products",
                                urlGrade: "assets/images/others/search.svg",
                                imagens: [
                                    {
                                        tipoImagem: 1,
                                        url: "assets/images/others/search.svg",
                                    },
                                ],
                                codigoGrade: "resultadoPesquisa",
                                ordem: 0,
                                codigoCor: "",
                                descricaoCor: "",
                                descricaoGrade: "All Products",
                            });
                        }
                    } else {
                        this.lista = [];
                    }

                    if (origemHome) {
                        if (this.lista.length > 1) {
                            this.router.navigate([
                                "/products/pesquisa",
                                value,
                                0,
                            ]);
                            this.pesquisaForm.reset();
                        } else {
                            this.snackBar.open(
                                `No results found: ${value}`,
                                "",
                                {
                                    panelClass: "warn",
                                    verticalPosition: "bottom",
                                    duration: 2000,
                                }
                            );
                            this.router.navigate(["/"]);
                        }
                    }

                    if (typeof callback === "function") {
                        callback(true, false, "succefuly");
                    }
                },
                error: (error: any) => {
                    this.loading = false;
                    if (typeof callback === "function") {
                        callback(false, true, error);
                    }
                },
            });
        } else if (value.length > 0 && value.length <= 2) {
            this.snackBar.open("Please enter at least 3 characters.", "", {
                panelClass: "warn",
                verticalPosition: "bottom",
                duration: 2000,
            });
        } else {
        }
    }

    public changeRoute(item) {
        if (
            item.codigoGrade !== "resultadoPesquisa" &&
            item.codigoGrade.length > 0
        ) {
            this.redirectTo("/products", item.codigoGrade, item.codigoCor);
        } else {
            this.loading = true;
            this.router.navigate([
                "/products/pesquisa",
                this.pesquisaForm.controls.valorPesquisa.value,
                0,
            ]);
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
        this.zerarLista();
    }

    redirectTo(uri: string, codigoGrade, codigoCor) {
        this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => this.router.navigate([uri, codigoGrade, codigoCor]));
    }

    public zerarLista() {
        this.lista = [];
        if (this.pesquisaForm) {
            this.pesquisaForm.controls.valorPesquisa.setValue("");
        }
    }

    public scrollToTop() {
        const scrollDuration = 200;
        const scrollStep = -window.pageYOffset / (scrollDuration / 20);
        const scrollInterval = setInterval(() => {
            if (window.pageYOffset !== 0) {
                window.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 10);
        if (window.innerWidth <= 768) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            });
        }
    }
    @HostListener("window:scroll", ["$event"])
    onWindowScroll($event) {
        $event.target.documentElement.scrollTop > 300
            ? (this.showBackToTop = true)
            : (this.showBackToTop = false);
    }

    ngAfterViewInit() {
        // this.isMobile = this.verificaMobile();
        this.isMobile = screen.width > 768 ? false : true;
        this.cdr.detectChanges();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.sidenav.close();
            }
        });
        this.sidenavMenuService.expandActiveSubMenu(
            this.sidenavMenuService.getSidenavMenuItems()
        );
    }

    public closeSubMenus() {
        if (window.innerWidth < 960) {
            this.sidenavMenuService.closeAllSubMenus();
        }
    }

    public getResumoCarrinho() {
        let log;
        this.sessionService.isLogged$.subscribe((data) => {
            log = data;
        });

        if (log) {
            this.cartService.resumoCarrinho(this.codCliente).subscribe(
                (item) => {
                    this.resumoCarrinho = item;
                    // console.log(this.resumoCarrinho)
                    this.getObterCarrinho();
                    this.isLogged = true;
                },
                (error: HttpErrorResponse) => {
                    // console.log('Error: ', error.error);
                }
            );
        }
    }

    public getObterCarrinho() {
        this.cartService.obterCarrinho(this.codCliente).subscribe((data) => {
            this.itensOriginal = [];
            if (Object.keys(data).length > 0) {
                // if (data.itens.length > 0 || data.itens && 'itens' in data) {
                if ("itens" in data) {
                    const itens: Array<CarrinhoRetornoItens> = [];
                    const itensSM0 = data.itens.filter(
                        (item) =>
                            item.vendaSobMedidaM2 !== 1 &&
                            item.lotes.length === 0
                    );
                    const itensSM1 = data.itens.filter(
                        (item) =>
                            item.vendaSobMedidaM2 === 1 &&
                            item.lotes.length === 0
                    );
                    const itensSM2 = data.itens.filter(
                        (item) =>
                            item.vendaSobMedidaM2 !== 1 && item.lotes.length > 0
                    );
                    const qtdItensSM0 = itensSM0.reduce(
                        (accumulator, pilot) => {
                            return accumulator + pilot.quantidade;
                        },
                        0
                    );
                    this.qtdeTotalItens =
                        qtdItensSM0 + itensSM1.length + itensSM2.length;
                    //   console.log(this.qtdeTotalItens);
                    this.itensCarrinho();

                    this.quantidadeLotes = itensSM2.length;
                    data.itens.forEach((item) => {
                        this.itensOriginal.push(item);
                        if (item.vendaSobMedidaM2 === 1) {
                            item.quantidade = 1;
                        }
                        const duplicated =
                            itens.findIndex((redItem) => {
                                //  return item.codProduto === redItem.codProduto;
                                if (
                                    item.codProduto === redItem.codProduto &&
                                    item.vendaSobMedidaM2 !== 1 &&
                                    item.lotes.length === 0
                                ) {
                                    redItem.quantidade += item.quantidade;
                                    return item;
                                }
                            }) > -1;

                        if (
                            item.lotes.length > 0 &&
                            item.descricao.indexOf("PECA") !== -1
                        ) {
                            item.descricao += ` - ${item.quantidade.toFixed(
                                2
                            )} mts`;
                        }

                        if (!duplicated) {
                            itens.push(item);
                        }
                    });

                    data.itens = itens;
                    this.carrinho = data;
                } else {
                    // this.carrinho.itens = [];
                    this.qtdeTotalItens = 0;
                }
            } else {
                this.carrinho = {
                    itens: [],
                    temCarrinho: false,
                };
                this.qtdeTotalItens = 0;
            }
        });
    }

    public excluirItem(item: CarrinhoRetornoItens) {
        const fil: Array<CarrinhoRetornoItens> = this.itensOriginal.filter(
            (x) => x.codProduto === item.codProduto
        );
        fil.forEach((val) => {
            this.cartService
                .excluirItem(
                    this.codCliente,
                    val.id,
                    val.idProduto,
                    val.codProduto
                )
                .subscribe(
                    (_) => {
                        this.snackBar.open(
                            `Delete item ${item.descricao} successfully`,
                            "",
                            {
                                panelClass: "success",
                                verticalPosition: "bottom",
                                duration: 2000,
                            }
                        );
                    },
                    (error: HttpErrorResponse) => {
                        this.snackBar.open(
                            `Error deleting item ${item.descricao}. Error: ${error.error}`,
                            "",
                            {
                                panelClass: "warn",
                                verticalPosition: "bottom",
                                duration: 2000,
                            }
                        );
                    }
                );
        });
        this.getResumoCarrinho();
    }

    public excluirCarrinho() {
        this.carrinho.itens.map((item) => {
            this.excluirItem(item);
        });
    }

    public itensCarrinho() {
        if (this.qtdeTotalItens > 0) {
            this.cartSelecionado = this.cartIcon.withItens;
        } else {
            this.cartSelecionado = this.cartIcon.noItens;
        }
    }

    public formatterValue(valor) {
        const val = (valor / 1).toFixed(2).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    public setFocusInput() {
        // this.zerarLista();
        document.getElementById("inputPesquisa").focus();
    }

    public divConosco() {
        this.router.navigate(["/"], { fragment: "divConosco" });
    }

    public isInteger(valor: number) {
        return Number.isInteger(valor);
    }

    public changeMenu(acessoMenu: boolean) {
        if (innerWidth >= 959) {
            if (this.isHome && !this.isMobile) {
                if (acessoMenu) {
                    this.logoWhatsAppSelecionado = this.linksWhats.positivo;
                    this.logoLupaSelecionado = this.lupaLogo.positivo;
                    this.logoCarrinhoSelecionado = this.cartLogo.positivo;
                }
            } else {
                this.logoWhatsAppSelecionado = this.linksWhats.negativo;
                this.logoLupaSelecionado = this.lupaLogo.negativo;
                this.logoCarrinhoSelecionado = this.cartLogo.negativo;
            }
        }
    }

    openMegaMenu(): void {
        const pane = document.getElementsByClassName("cdk-overlay-pane");
        [].forEach.call(pane, (el) => {
            if (el.children.length > 0) {
                if (el.children[0].classList.contains("mega-menu")) {
                    el.classList.add("mega-menu-pane");
                }
            }
        });
    }

    // public openCatalog(item): any {
    //   return window.open(item, '_blank');
    // }

    public openLink(item): any {
        return window.open(item, "_blank");
    }

    public routerTo(item): any {
        return this.router.navigate([item]);
    }

    private verificaEselecionaSubMenus(param: any) {
        this.menus.forEach((item) => {
            if (
                param.link.indexOf("Rugs") != -1 &&
                item.rota.indexOf("Rugs") != -1
            ) {
                item.menuSelecionado = true;
            } else if (
                param.link.indexOf("events") != -1 &&
                item.rota.indexOf("events") != -1
            ) {
                item.menuSelecionado = true;
            } else {
                item.menuSelecionado = false;
            }
        });
    }

    public menuRouterTo(item): any {
        this.verificaEselecionaSubMenus(item);
        if (item.possuiAcao) {
            if (item.link) {
                if (item.linkInterno) {
                    return this.router.navigate([item.link]);
                } else {
                    return window.open(item.link, "_blank");
                }
            } else if (item.rota === "/contato") {
                return this.openDialogContact();
            }
        } else {
            this.menus.forEach((item) => (item.menuSelecionado = false));
            item.menuSelecionado = true;
            return this.router.navigate([item.rota]);
        }
    }

    public openDialogContact() {
        let isMobile = {
            Android: function () {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function () {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function () {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: function () {
                return (
                    navigator.userAgent.match(/IEMobile/i) ||
                    navigator.userAgent.match(/WPDesktop/i)
                );
            },
            any: function () {
                return (
                    isMobile.Android() ||
                    isMobile.BlackBerry() ||
                    isMobile.iOS() ||
                    isMobile.Opera() ||
                    isMobile.Windows()
                );
            },
        };
        if (isMobile.any() === null) {
            this.dialog.open(ModalContactComponent, {
                data: {},
                height: "650px",
                width: "650px",
                maxWidth: "50vw",
                maxHeight: "100vh",
                panelClass: "dialog-container-arredondado",
            });
        } else {
            this.dialog.open(ModalContactComponent, {
                data: {},
                maxWidth: "46vw",
                maxHeight: "85vh",
                height: "100%",
                width: "100%",
                panelClass: "dialog-container-arredondado",
            });
        }
    }

    public openDialogCart() {
        console.log(this.carrinho);
        if (!this.carrinho) {
            // this.carrinho.itens = [];
            this.qtdeTotalItens = 0;
        }

        const dialogRef: MatDialogRef<ModalCartComponent> = this.dialog.open(
            ModalCartComponent,
            {
                data: this.carrinho,
                height: "520px",
                width: "997px",
                maxWidth: "100vw",
                maxHeight: "100vh",
                panelClass: "dialog-container-arredondado",
            }
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getObterCarrinho();
            }
        });
    }

    verificaMenu() {
        if (this.isHome && !this.isMobile) {
            this.logoSelecionado = this.linksLogo.positivo;
            this.logoWhatsAppSelecionado = this.linksWhats.positivo;
            this.logoLupaSelecionado = this.lupaLogo.positivo;
            this.logoCarrinhoSelecionado = this.cartLogo.positivo;
        } else {
            this.logoWhatsAppSelecionado = this.linksWhats.negativo;
            this.logoSelecionado = this.linksLogo.negativo;
            this.logoLupaSelecionado = this.lupaLogo.negativo;
            this.logoCarrinhoSelecionado = this.cartLogo.negativo;
        }
    }

    toggleSearch() {
        this.showSearch = !this.showSearch;
    }

    hideSearch() {
        this.showSearch = false;
    }

    verificaMobile(): boolean {
        let isMobile = {
            Android: function () {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function () {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function () {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: function () {
                return (
                    navigator.userAgent.match(/IEMobile/i) ||
                    navigator.userAgent.match(/WPDesktop/i)
                );
            },
            any: function () {
                return (
                    isMobile.Android() ||
                    isMobile.BlackBerry() ||
                    isMobile.iOS() ||
                    isMobile.Opera() ||
                    isMobile.Windows()
                );
            },
        };
        return isMobile.any() !== null || screen.width > 768 ? true : false;
    }
}
