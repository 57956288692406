import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    Renderer2,
    ViewChild,
} from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
    NgForm,
    Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBar,
    MatSnackBarRef,
} from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { SelectOptions } from "src/app/interfaces/select-options.model";
import {
    ListaEstados,
    ListaEstadosService,
} from "src/app/services/lista-estados/lista-estados.service";
import {
    ListaPaises,
    ListaPaisesService,
} from "src/app/services/lista-paises/lista-paises.service";
import { SessionService } from "src/app/services/session/session.service";
import { ObterIdentificador } from "src/app/services/sign-in/obter-identificador.service";
import { SignInService } from "src/app/services/sign-in/sign-in.service";
import { UtilService } from "src/app/services/util/util.service";
import { ModalContactComponent } from "src/app/shared/modal-contact/modal-contact.component";
import { emailValidator } from "src/app/theme/utils/app-validators";

@Component({
    selector: "app-open-trade-account",
    templateUrl: "./open-trade-account.component.html",
    styleUrls: ["./open-trade-account.component.scss"],
})
export class OpenTradeAccountComponent implements OnInit {
    // errorMatcher = new CustomErrorStateMatcher();

    maskTelefone: string;
    selectedValue: string;
    hide = true;
    disabled = true;
    identificador: any = JSON.parse(localStorage.getItem("empresa"));

    registerForm: FormGroup;

    value: any;
    readonlyForm = false;

    tiposContatos: Array<SelectOptions> = [];

    tiposEnderecos: Array<SelectOptions> = [];

    public states: Array<ListaEstados> = [];

    public businessType: Array<SelectOptions> = [
        { label: "E-commerce only", value: "000003" },
        { label: "Interior Designer", value: "000006" },
        { label: "Retailer", value: "000007" },
    ];

    public salesTaxStatus: Array<SelectOptions> = [
        { label: "Non-exempt", value: "1" },
        { label: "Exempt", value: "2" },
        { label: "I'll send by e-mail", value: "3" },
    ];

    public minoriCasaRep: Array<SelectOptions> = [
        { label: "No", value: "1" },
        { label: "Yes", value: "2" },
    ];

    public referenceResource: Array<SelectOptions> = [
        { value: "Representative", label: "Representative" },
        { value: "Web search", label: "Web search" },
        { value: "Tradeshow", label: "Tradeshow" },
        { value: "Social Media", label: "Social Media" },
        { value: "E-mail", label: "E-mail" },
        { value: "Magazine Add", label: "Magazine Add" },
        { value: "Word of mouth", label: "Word of mouth" },
        { value: "Other", label: "Other (please fill in box)" },
    ];

    dadosEmpresa: ObterIdentificador;

    // para efetuar o focus no elemento número
    @ViewChild("numero") numero: ElementRef;

    public loading = false;

    // public modal: boolean = false;

    public resaleCertificate: File;

    public consent: boolean = false;

    public countries: Array<ListaPaises> = [];

    public divSucess = false as boolean;

    constructor(
        private fb: FormBuilder,
        private utilService: UtilService,
        private router: Router,
        private sessionService: SessionService,
        public dialog: MatDialog,
        private listaEstadosService: ListaEstadosService,
        private signInService: SignInService,
        private snackBar: MatSnackBar,
        private listaPaisesService: ListaPaisesService,
        private title: Title
    ) {
        this.title.setTitle(`Open Trade Account - Minori Casa`);
    }

    ngOnInit() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 1000);
        window.scrollTo(0, 0);
        const validUrl =
            "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
        this.registerForm = this.fb.group({
            A1_NOME: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            firstName: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(25),
                ]),
            ],
            lastName: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(25),
                ]),
            ],
            A1_HPAGE: ["", []],
            A1_CODSEG: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(30),
                ]),
            ],
            A1_EMAIL: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                    emailValidator,
                ]),
            ],
            A1_TEL: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(16),
                ]),
            ],
            A1_EST: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(2),
                ]),
            ],
            A1_PAIS: [
                "US",
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(3),
                ]),
            ],
            stateDescription: ["", Validators.compose([Validators.required])],
            countryDescription: [""],
            password: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(12),
                ]),
            ],
            resaleCertificateNumber: [
                "",
                Validators.compose([Validators.maxLength(30)]),
            ],
            salesTaxStatus: ["1", Validators.required],
            minoriCasaRep: ["1", Validators.required],
            salesman: ["", []],
            referenceResource: ["", Validators.required],
            otherReferenceResource: ["", []],
            urlResaleCertificate: ["", []],
            A1_END: ["", Validators.required],
            A1_CEP: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(8),
                    Validators.pattern(/[0-9]/g),
                ]),
            ],
            A1_MUN: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        `^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$`
                    ),
                ]),
            ],
            consentData: [false, Validators.requiredTrue],
        });

        this.listarPaises();

        this.registerForm.valueChanges.subscribe((_) => {
            this.disabled = this.registerForm.invalid;
        });

        this.registerForm.controls.consentData.valueChanges.subscribe((x) => {
            this.consent = x;
        });

        this.sessionService.isLogged$.subscribe((data) => {
            if (data && this.router.url === "/user-registration") {
                this.router.navigate(["/"]);
            }
        });

        this.dadosEmpresa = JSON.parse(localStorage.getItem("identificador"));
    }

    get registerControls() {
        return this.registerForm.controls;
    }

    addItem(newItem: File) {
        this.resaleCertificate = newItem;
        console.log(this.resaleCertificate);
    }

    realizarCadastro(form, formDirective: FormGroupDirective) {
        if (
            !this.resaleCertificate &&
            this.registerControls.salesTaxStatus.value === "2"
        ) {
            this.snackBar.open("Please put the Reseller Certificate", "", {
                panelClass: "warning",
                verticalPosition: "bottom",
                duration: 4000,
            });
        } else if (this.registerForm.invalid) {
            this.camposInvalidos();
        } else {
            this.loading = !this.loading;
            form.urlResaleCertificate = this.resaleCertificate ?? "";
            form.A1_CONTATO = `${form.firstName} ${form.lastName}`;
            form.A1_NREDUZ = form.A1_NOME;
            form.dateTime = `Date: ${this.utilService.dataAtual()} Time: ${this.utilService.horaAtual()}`;
            delete form.firstName;
            delete form.lastName;
            const envio = [form];

            envio.map((item) => {
                Object.keys(item).map((data) => {
                    if (data !== "consentData") {
                        if (
                            data === "A1_HPAGE" ||
                            data === "A1_EMAIL" ||
                            data === "password" ||
                            data === "urlResaleCertificate"
                        ) {
                            item[data] = item[data];
                        } else {
                            if (item[data]) {
                                item[data] = this.utilService.removeAccents(
                                    item[data]
                                );
                            }
                        }
                    }
                });
                return item;
            });
            console.log(envio[0], "-------->>> ENVIO JSON");
            this.signInService.registerUser(JSON.stringify(envio[0])).subscribe(
                (data) => {
                    console.log(data, "RETORNO success ---->> DATA");
                    if (data.error) {
                        this.snackBar.open(`${data.error}`, "", {
                            panelClass: "error",
                            verticalPosition: "bottom",
                            duration: 6000,
                        });
                    } else {
                        this.snackBar.openFromComponent(
                            SnackBarFixedComponent,
                            {
                                panelClass: "success",
                                verticalPosition: "bottom",
                            }
                        );
                        formDirective.resetForm();
                        this.registerForm.reset();
                        this.registerControls.salesTaxStatus.setValue("1");
                        this.registerControls.minoriCasaRep.setValue("1");
                        this.resaleCertificate = null;
                        this.divSucess = true;
                        window.scrollTo(0, 0);
                    }
                    this.loading = !this.loading;
                },
                (err) => {
                    console.log(err, "ERROR");
                    this.snackBar.open(
                        "Error, please check your form and try again.",
                        "",
                        {
                            panelClass: "error",
                            verticalPosition: "bottom",
                            duration: 4000,
                        }
                    );
                    this.loading = !this.loading;
                }
            );
        }
    }

    public camposInvalidos() {
        const invalidFields = [];
        Object.keys(this.registerForm.controls).forEach((field) => {
            const control = this.registerForm.get(field);
            if (control?.invalid) {
                invalidFields.push(field);
            }
        });

        const mapping = {
            A1_NOME: "Company Name",
            firstName: "First Name",
            lastName: "Last Name",
            A1_HPAGE: "Company Website",
            A1_CODSEG: "Business Type",
            A1_EMAIL: "Email",
            A1_TEL: "Phone",
            A1_EST: "State",
            A1_PAIS: "Country",
            stateDescription: "State Description",
            countryDescription: "Country Description",
            password: "Password",
            resaleCertificateNumber: "Resale Certificate Number",
            salesTaxStatus: "Sales Tax Status",
            minoriCasaRep: "Minori Casa Rep",
            salesman: "Salesman",
            referenceResource: "Reference Resource",
            otherReferenceResource: "Other Reference Resource",
            urlResaleCertificate: "Resale Certificate",
            A1_END: "Street",
            A1_CEP: "Zip Code",
            A1_MUN: "City",
        };

        const formAjustado = invalidFields
            .map((field) => {
                return mapping[field];
            })
            .join(", ");

        this.snackBar.open(
            `Please check the following fields and try again: ${formAjustado}`,
            "",
            {
                panelClass: "warning",
                verticalPosition: "bottom",
                duration: 10000,
            }
        );
    }

    public clearFilter() {
        this.registerForm.reset({
            razaoSocial: [],
            cnpj: [],
            ddd: [],
            telefone: [],
            tipoEndereco: [],
            logradouro: [],
            numero: [],
            bairro: [],
            cep: [],
            cidade: [],
            estado: [],
            complemento: [],
            tipoContato: [],
            dddContato: [],
            telefoneContato: [],
            nomeContato: [],
            emailContato: [],
            confirmarEmailContato: [],
            confirmarSenhaContato: [],
            senha: [],
        });
        this.readonlyForm = false;
        this.registerForm.enable();
    }

    public openDialogContact() {
        let isMobile = {
            Android: function () {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function () {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function () {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: function () {
                return (
                    navigator.userAgent.match(/IEMobile/i) ||
                    navigator.userAgent.match(/WPDesktop/i)
                );
            },
            any: function () {
                return (
                    isMobile.Android() ||
                    isMobile.BlackBerry() ||
                    isMobile.iOS() ||
                    isMobile.Opera() ||
                    isMobile.Windows()
                );
            },
        };
        if (isMobile.any() === null) {
            this.dialog.open(ModalContactComponent, {
                data: {},
                height: "650px",
                width: "650px",
                maxWidth: "50vw",
                maxHeight: "100vh",
                panelClass: "dialog-container-arredondado",
            });
        } else {
            this.dialog.open(ModalContactComponent, {
                data: {},
                maxWidth: "100vw",
                maxHeight: "100vh",
                height: "100%",
                width: "100%",
                panelClass: "dialog-container-arredondado",
            });
        }
    }

    private listarPaises() {
        this.listaPaisesService.listaPaises().subscribe((data) => {
            if (data.status) {
                const retorno = data.dados;
                this.countries = retorno.sort((a, b) => a.ordem - b.ordem);
            } else {
                this.countries = [];
            }
        });
    }

    private listarEstadosPorPais(codPais) {
        this.listaEstadosService
            .listaEstadosPorPais(codPais)
            .subscribe((data) => {
                console.log(data, "data");
                if (data.status) {
                    const retorno = data.dados;
                    this.states = retorno.sort((a, b) => a.ordem - b.ordem);
                } else {
                    this.states = [];
                }
            });
    }

    public selectContries(event) {
        if (event) {
            const filteredCountry = this.countries.filter(
                (item) => item.codPais === event.value
            );
            this.registerControls.A1_EST.setValue("");
            this.registerControls.stateDescription.setValue("");
            this.registerControls.countryDescription.setValue(
                filteredCountry[0].nome
            );
            this.listarEstadosPorPais(event.value);
        }
    }

    public selectState(event) {
        if (event) {
            const filteredState = this.states.filter(
                (item) => item.sigla === event.value
            );
            this.registerControls.stateDescription.setValue(
                filteredState[0].descricao
            );
        }
    }

    public selectBusinessType(event) {
        const validUrl =
            "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
        if (event) {
            console.log(event);
            if (event.value === "000006") {
                this.registerControls["A1_HPAGE"].clearValidators();
                this.registerControls["A1_HPAGE"].setValidators(
                    Validators.pattern(validUrl)
                );
                this.registerControls["A1_HPAGE"].updateValueAndValidity();
            } else {
                this.registerControls["A1_HPAGE"].clearValidators();
                this.registerControls["A1_HPAGE"].setValidators(
                    Validators.compose([
                        Validators.required,
                        Validators.pattern(validUrl),
                    ])
                );
                this.registerControls["A1_HPAGE"].updateValueAndValidity();
            }
        }
    }
}

@Component({
    selector: "app-fixed-snackbar",
    template: ` Form saved successfully <br />
        You will receive a confirmation email within 48 hours`,
    styles: [],
})
export class SnackBarFixedComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data,
        private snackRef: MatSnackBarRef<SnackBarFixedComponent>,
        ren: Renderer2
    ) {
        setTimeout(() => {
            const snackEl = document
                .getElementsByClassName("mat-snack-bar-container")
                .item(0);
            ren.listen(snackEl, "click", () => this.dismiss());
        });
    }

    dismiss() {
        this.snackRef.dismiss();
    }
}

export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl,
        form: NgForm | FormGroupDirective | null
    ) {
        return control && control.invalid && control.touched;
    }
}
